@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'libs/neurocare-ui/src/styles/neurocare-theme';

mat-divider {
  @apply bg-neutral-300;
}

survey ::-webkit-calendar-picker-indicator {
  display: block;
}
